<template>
   <div class="col-12 px-0" ref="lancamento">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ lancamento.nomeEmitente == null || String(lancamento.nomeEmitente).trim().length == 0 ? 'Não definido' : lancamento.nomeEmitente }}</h1>
                        <p class="font-10 mb-2 limitador-1 text-secondary">
                           <span><i class="far fa-store font-8 me-1"></i>{{ lancamento.lojaNome == null || String(lancamento.lojaNome).trim().length == 0 ? 'Não definido' : lancamento.lojaNome }}</span>
                           <small class="mx-1">|</small>
                           <span><i class="far fa-tag font-8 me-1"></i>{{ lancamento.lojaNumero }}</span>
                           <span class="ms-3 d-none d-md-inline">
                              <i class="far fa-info-circle font-8 me-1"></i>
                              <span>{{ lancamento.observacoesEmitente == null || String(lancamento.observacoesEmitente).trim().length == 0 ? 'Sem observações' : lancamento.observacoesEmitente }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> CPF/CNPJ emitente:</strong>
                           <span>{{ lancamento.cpfCnpjEmitente == null || String(lancamento.cpfCnpjEmitente).trim().length == 0 ? 'Não definido' : lancamento.cpfCnpjEmitente }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1" v-if="lancamento.dataNota != null && String(lancamento.dataNota).trim().length > 0">
                           <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Data:</strong><span>{{ lancamento.dataNota }}</span>
                        </p>
                        <p class="font-12 mb-0 limitador-1" v-else>
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1"> Quant. boletos:</strong><span>{{ lancamento.boletos.length }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-125 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15" v-if="!showEdit">
                  <span class="d-block lh-1"><small>R$ </small>{{ parseFloat(lancamento.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                  <small class="font-12"><i class="far fa-money-bill color-theme font-10 me-1"></i> Valor</small>   
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-else-if="dadosUsuario.usuarioPermissoes.includes('Financeiro | Editar gastos')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Lancamento',
   props: ['lancamento', 'index', 'showEdit'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         if (!this.showEdit || this.dadosUsuario.usuarioPermissoes.includes('Financeiro | Editar gastos')) {
            this.$emit('editar', this.lancamento)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir lançamento?',
            text: 'O lançamento de gasto será permanentemente excluído.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
   
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
   
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'financeiro/deleteLancamentoGasto',
                  params: {
                     id: this.lancamento.id
                  }
               }).then(() => {
                  this.$refs.lancamento.style.display = 'none'
   
                  this.$toast.fire({
                     icon: 'success',
                     title: 'Lançamento excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')
   
                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>